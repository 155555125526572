import {
  PageHeader, TextBlock, Banner, HighlightLinks, PageHeaderVideoModal, CtaButton, TrainerOverview, VideoBlock,
  CarouselBlock, UspBlock, ImageBlock, SpotifyEmbed, InstagramGrid, FilteredSchedule, AccordionBlock,
  TextColumns, ReviewCarousel, HolyFriends, PageHeaderHalfHeight, FormBlock, PricingBlock, PricingAdvancedBlock,
} from 'modules/contentBlocks';

export const ComponentList = {
  ContentfulComponentHeroHeaderFullHeight: PageHeader,
  ContentfulComponentHeroHeaderHalfHeight: PageHeaderHalfHeight,
  ContentfulComponentTextBlock: TextBlock,
  ContentfulComponentBanner: Banner,
  ContentfulComponentHighlightImageLinks: HighlightLinks,
  ContentfulComponentCarousel: CarouselBlock,
  ContentfulComponentHeroHeaderVideoModal: PageHeaderVideoModal,
  ContentfulComponentCtaButton: CtaButton,
  ContentfulComponentTrainersOverview: TrainerOverview,
  ContentfulComponentVideoBlock: VideoBlock,
  ContentfulComponentUspBlock: UspBlock,
  ContentfulComponentImageBlock: ImageBlock,
  ContentfulComponentSpotifyEmbed: SpotifyEmbed,
  ContentfulComponentInstagramGrid: InstagramGrid,
  ContentfulComponentFilteredSchedule: FilteredSchedule,
  ContentfulComponentAccordion: AccordionBlock,
  ContentfulComponentTextColumns: TextColumns,
  ContentfulComponentReviewCarousel: ReviewCarousel,
  ContentfulComponentHolyFriends: HolyFriends,
  ContentfulComponentForm: FormBlock,
  ContentfulComponentPricingBlock: PricingBlock,
  ContentfulComponentPricingAdvancedBlock: PricingAdvancedBlock,
};
