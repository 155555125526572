import * as i from 'types';
import React from 'react';
import { graphql } from 'gatsby';

import { PageHeader, PageHeaderHalfHeight } from 'modules/contentBlocks';
import { ContentContainer, Template } from 'common/layout';
import { ContentArea } from 'common/contentarea';
import { TextContent } from 'common/typography';
import { TrainerCarousel } from 'modules/TrainerPage/TrainerCarousel';

const TrainerPage: React.FC<TrainerPageProps> = ({ data }) => {
  const trainer = data.contentfulTypeTrainer;
  const headerImage = trainer?.headerImage as i.MediaType;
  const headerImageMobile = trainer?.headerImageMobile as i.MediaType;
  const fullHeader = trainer?.hasFullHeader;
  const body = trainer?.body as i.ContentAreaBlocksTypes[] || [];

  const HeaderComponent = fullHeader ? PageHeader : PageHeaderHalfHeight;
  const typename = fullHeader ? 'ContentfulComponentHeroHeaderFullHeight' : 'ContentfulComponentHeroHeaderHalfHeight';

  return (
    <Template>
      <HeaderComponent
        content={{
          title: trainer?.name || '',
          headerTitle: trainer?.name || '',
          subtitle: trainer?.tagline || '',
          backgroundMedia: headerImage,
          backgroundMediaMobile: headerImageMobile,
          __typename: typename,
          id: headerImage.id,
          centered: true,
        }}
      />
      {trainer && (
        <ContentContainer>
          <TextContent content={trainer?.biographyLong?.raw || ''} alignment="center" />
        </ContentContainer>
      )}
      {body.length !== 0 && (
        <ContentArea contentTypes={body} />
      )}
      {trainer?.relatedTrainers && trainer.relatedTrainers.length > 0 && (
        <TrainerCarousel trainers={trainer?.relatedTrainers} />
      )}
    </Template>
  );
};

export default TrainerPage;

export const query = graphql`
  query TrainerPage($id: String, $language: String) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
    contentfulTypeTrainer(id: {eq: $id}, node_locale: {eq: $language}) {
      slug
      name
      hasFullHeader
      node_locale
      tagline
      headerImage {
        id
        description
        file {
          url
          contentType
        }
      }
      headerImageMobile {
        id
        description
        file {
          url
          contentType
        }
      }
      biographyLong {
        raw
      }
      relatedTrainers {
        name
        tagline
        slug
        frontImage {
          id
          file {
            url
          }
        }
      }
      body {
        __typename
        ...PageBodyFragment
      }
    }
  }
`;

type TrainerPageProps = {
  data: GatsbyTypes.TrainerPageQuery;
};
