import * as i from 'types';
import * as React from 'react';

import { Carousel } from 'common/interaction';

export const TrainerCarousel: React.FC<TrainerCarouselProps> = ({ trainers }) => {
  const slides = trainers.map((trainer) => ({
    ...trainer,
    title: trainer.name,
    cta: `/trainers/${trainer.slug}`,
  }));

  return (
    <Carousel slides={slides} />
  );
};

type TrainerCarouselProps = {
  trainers: {
    name: string;
    slug: string;
    tagline: string;
    frontImage: i.MediaType;
  }[]
};
